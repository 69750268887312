import React from 'react';
import './App.css';
import { Api, JsonRpc, RpcError } from 'enf-eosjs';
import Link from 'anchor-link';
import BrowserTransport from 'anchor-link-browser-transport';
import { uint64ToName } from 'eosjs-account-name';

function App() {
	const [isLoggedIn, setIsLoggedIn] = React.useState(false);
	const [session, setSession] = React.useState({});
	const [accountInfo, setAccountInfo] = React.useState({});
	const [archiveRecords, setArchiveRecords] = React.useState([]);
	const [accountName, setAccountName] = React.useState("");
	const [errorString, setErrorString] = React.useState("");
	const [log, setLog] = React.useState("");

	const identifier = 'example';
	// initialize the browser transport
	const transport = new BrowserTransport();
	// initialize the link
	const link = new Link({
		transport,
		chains: [{
			chainId: '8fc6dce7942189f842170de953932b1f66693ad3788f766e777b6f9d22335c02',
			nodeUrl: 'https://explorer.uxnetwork.io',
		}]
	});

	const getAccountInfo = React.useCallback(() => {
		const rpc = new JsonRpc('https://explorer.uxnetwork.io:443', { fetch });
		const api = new Api({ rpc, textDecoder: new TextDecoder(), textEncoder: new TextEncoder() });

		rpc.get_table_rows({
			json: true,
			code: 'mbr.hauer',
			scope: 'mbr.hauer',
			table: 'hauerpays',
			lower_bound: accountName,
			limit: 1,
			reverse: false,
			show_payer: false,
		}).then(resp => {
			//console.log(resp.rows);
			rpc.get_table_rows({
				json: true,
				code: 'mbr.hauer',
				scope: 'mbr.hauer',
				table: 'hparchives',
				//lower_bound: accountName,
				limit: 1000,
				reverse: false,
				show_payer: false,
			}).then(resp2 => {
				console.log(resp2.rows);
				var filteredArchiveRecords = resp2.rows.filter(r => r.account === accountName && (r.unclaimed != "0.0000 UTX" || r.principal != "0.0000 UTX"));
				console.log(filteredArchiveRecords);

				if ((resp.rows.length > 0 && accountName === resp.rows[0].account) || filteredArchiveRecords.length > 0) {
					if (resp.rows.length > 0) {
						setAccountInfo(resp.rows[0]);
					}

					if (filteredArchiveRecords.length > 0) {
						setArchiveRecords(filteredArchiveRecords);
					}

					setErrorString("");
					setIsLoggedIn(true);				
				} else {
					setIsLoggedIn(false);
					setErrorString("No Rewards or Principal available for \"" + accountName + "\"");
				}
			});
		});
	}, [accountName]);
	
	React.useEffect(() => {
		if (accountName != "")
			getAccountInfo();
	}, [accountName]);
	
	const ctransfer = () => {
		const action = {
			account: 'escrow.hauer',
			name: 'claimpay',
			authorization: [session.auth],
			data: {
				account: '............1'
			}
		}
		session.transact({action}).then((result) => {
			setLog(`Transaction broadcast! ${ result.processed.id }\n`);
			setTimeout(getAccountInfo, 500);
		});
	};

	const ptransfer = () => {
		const action = {
			account: 'escrow.hauer',
			name: 'getprincipal',
			authorization: [session.auth],
			data: {
				account: '............1'
			}
		}
		session.transact({action}).then((result) => {
			setLog(`Transaction broadcast! ${ result.processed.id }\n`);
			setTimeout(getAccountInfo, 500);
		});
	};
	
	const logout = () => {
		session.remove();
		setSession({});
		setLog("");
		setIsLoggedIn(false);
		setArchiveRecords([]);
	};
	
	const login = () => {
		link.login(identifier).then((result) => {
			setSession(result.session);
			setAccountName(result.session.auth.actor.toString());
		})
	};

	return (
		<div className="App">
			<h5>Hauer Rewards</h5>
			{(isLoggedIn === true) ?
				<div>
					<div>Welcome <b id="account-name">{accountName}</b>!</div>
					<div style={{ marginTop: "12px" }}>Active: {accountInfo.unclaimed}</div>
					{(archiveRecords.length > 0) ? 
						<div>
							<table style={{ border: "1px black" }}>
								<thead>
									<tr>
										<td>Unclaimed</td>
										<td>Principal</td>
									</tr>
								</thead>
								<tbody>
								{archiveRecords.map(ar =>
									<tr>
										<td>{ar.unclaimed}</td>
										<td>{ar.principal}</td>
									</tr>
								)}
								</tbody>
							</table>
						</div>
					:
						<div></div>
					}
					<div style={{ marginTop: "12px" }}><button onClick={ctransfer}>Claim Rewards</button></div>
					<div><button onClick={ptransfer}>Get Principal</button></div>
					<div><button onClick={logout}>Logout</button></div>
				</div>
			:
				<div>
					{(errorString != "") ?
						<div style={{ textColor: "red" }}>{errorString}</div>
					:
						<div></div>
					}
					<div><button onClick={login}>Connect w/Anchor</button></div>
				</div>
			}
			<pre id="log">{log}</pre>
		</div>
	);
}

export default App;
